<template>
  <div class="procedure-container bg-white">
    <div class="flex items-center lg:p-20 max-sm:flex-col">
      <div class="lg:basis-1/2 max-sm:basis-auto max-sm:w-full relative">
        <h4
          class="absolute top-1/2 left-1/3 -translate-x-full -translate-y-1/2 text-2xl text-white uppercase font-normal"
        >
          {{ $t('Diễn đàn') }}
        </h4>
        <img class="w-full cursor-pointer" src="/images/user/procedure.png" alt="procedure" @click="redirectForum">
      </div>
      <div class="lg:basis-1/2 max-sm:basis-auto max-sm:w-full">
        <h3
          class="
            pl:ml-16
            ml-8
            lg:text-xl
            text-base
            font-normal
            py-3
            text-textBlackPrimary
            max-sm:text-center
            max-w-[90%]
          "
        >
          {{ $t('Không gian thân thiện và cởi mở để doanh nghiệp thảo luận về các chủ đề thương mại quốc tế.') }}
        </h3>
      </div>
    </div>
    <template v-if="slideForums.length >= 5">
      <div class="forum-carousel px-0 lg:px-14 lg:py-8 py-5 relative">
        <div class="button-prev w-8 h-8 rounded-full border border-[#8E8E8E] flex justify-center items-center cursor-pointer text-xl hover:border-blue-400 hover:text-blue-400 absolute left-5 top-1/2 -translate-y-1/2 z-10" @click.prevent="prevListForums">
          <i class="ri-arrow-left-s-line" />
        </div>
        <Carousel ref="forumSlide" :settings="settings">
          <Slide v-for="(item, index) in slideForums" :key="index">
            <div class="slide-item cursor-pointer" @click.prevent="gotoForum(item.slug)">
              <div class="slide-img">
                <img
                  class="object-cover w-full h-48 rounded-md "
                  :src="item.image_path ? item.image_url : '/images/forums/forum_default.svg'"
                  alt=""
                >
              </div>
              <div class="slide-name mt-4 cursor-pointer text-ellipsis-2 text-center">
                {{ item.name }}
              </div>
            </div>
          </Slide>
        </Carousel>
        <div
          class="button-next w-8 h-8 rounded-full border border-[#8E8E8E] flex justify-center items-center cursor-pointer text-xl hover:border-blue-400 hover:text-blue-400 absolute right-5 top-1/2 -translate-y-1/2 z-10"
          @click.prevent="nextListForums"
        >
          <i class="ri-arrow-right-s-line" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex max-md:flex-wrap justify-center items-start lg:gap-4 py-5">
        <div v-for="(item, index) in slideForums" :key="index" class="slide-item xl:w-1/5 lg:w-1/3 sm:w-1/2 w-full cursor-pointer max-xl:px-3" @click.prevent="gotoForum(item.slug)">
          <div class="slide-img">
            <img
              class="object-cover w-full h-48 rounded-md "
              :src="item.image_path ? item.image_url : '/images/forums/forum_default.svg'"
              alt=""
            >
          </div>
          <div class="slide-name mt-4 cursor-pointer text-ellipsis-2 text-center">
            {{ item.name }}
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import axios from '@/plugins/axios'
import 'vue3-carousel/dist/carousel.css'

import { Carousel, Slide } from 'vue3-carousel'
export default {
  components: { Carousel, Slide },
  data() {
    return {
      news: [],
      slideForums: [],
      settings: {
        itemsToShow: 5,
        wrapAround: true,
        autoplay: 5000,
        breakpoints: {
          300: {
            itemsToShow: 1,
            snapAlign: 'center',
          },
          700: {
            itemsToShow: 3,
            snapAlign: 'center',
          },
          1280: {
            itemsToShow: 5,
            snapAlign: 'center',
          }
        },
      },
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const { status, data } = await axios.get('/get-all-public-forums')

      if (status === 200) {
        this.forums = data?.data
        this.slideForums = this.forums.filter((item) => item.type !== 2)
        this.paginate = data?.meta
      }
    },
    gotoForum(slug) {
      // eslint-disable-next-line no-undef
      this.$inertia.visit(route('forums.detail', { slug: slug }))
    },
    formatDate(dateString) {
      const date = new Date(dateString)
      return new dayjs(date).format('DD-MM-YYYY')
    },
    redirectForum() {
      this.$inertia.visit(this.route('forum.list'))
    },
    prevListForums() {
      this.$refs.forumSlide.prev()
    },
    nextListForums() {
      this.$refs.forumSlide.next()
    },
  },
}
</script>
<style scoped>
.carousel__slide {
  padding: 10px;
  align-items: start;
}

.text-ellipsis-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
